//
// Button
// --------------------------------------------------

[class^='button-'] {
  i {
    vertical-align: middle;
  }
}

%button-base {
  display: inline-block;
  transition: all .1s linear;
  padding: .7em 1.4em;
  border: 1px solid #000;
  color: $text-color;
  &:visited, &:active, &:focus {
    text-decoration: none;
  }
  &:hover {
    color: #fff;
    background: #000;
    text-decoration: none;
  }
  i, img {
    vertical-align: middle;
  }
}

%button-inverted {
  background: #fff;
  &:visited, &:active, &:focus {
    background: #fff;
  }
  &:hover {
    background: #f5f5f5;
  }
}

%button-link {
  white-space: nowrap;
  display: inline-block;
  transition: all .1s linear;
  color: $text-color;
  border-bottom: 1px solid $text-color;
  &:hover {
    text-decoration: none;
    color: inherit;
    border-bottom-color: transparent;
  }
}

%button-xs {
  @include font-size($fs-xs);
  padding: .5em 1.5em;
}

%button-sm {
  @include font-size($fs-sm);
}

%button-lg {
  @include font-size($fs-lg);
}

.button {
  &-default {
    @extend %button-base;
  }
  &-inverted {
    @extend %button-base;
    @extend %button-inverted;
  }
  &-link {
    @extend %button-link;
  }
  &-xs {
    @extend %button-xs;
  }
  &-sm {
    @extend %button-sm;
  }
  &-lg {
    @extend %button-lg;
  }
}
