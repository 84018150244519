//
// Layout
// --------------------------------------------------

.wrap {
  @extend .container-lg;
}

main.main {
  @media #{$breakpoint-lg-up} {
    margin-top: $spacer;
  }
}

footer.content-info {
  margin-top: $spacer
}
