//
// Panel
// --------------------------------------------------

%panel-base {
  margin-bottom: $spacer / 2;
  @media #{$breakpoint-sm-up} {
    margin-bottom: $spacer;
  }
}

%poster-base {
}

%poster-inner-base {
  background-position: 50%;
  background-repeat: none;
  background-size: cover;
}

.panel {
  @extend %panel-base;
  &-link {
    display: block;
    &:hover {
      .panel-poster-inner {
        transform: scale(1.01);
        opacity: 0.8;
      }
    }
  }
  &-poster {
    margin-bottom: $spacer / 2;
    overflow: hidden;
    @include aspect-ratio(3, 2, ".panel-poster-inner");
    &-inner {
      transition: all 0.1s linear;
      @extend %poster-inner-base;
    }
  }
  &-summary {
    .list-taxonomy {
      position: absolute;
      top: 0;
      right: -0.7em;
      > li {
        margin: 0;
      }
    }
    position: relative;
    padding-right: 2rem;
    &-title {
      margin-top: $spacer / 4;
      @include font-size($fs-base);
      font-weight: normal;
      > a {
        color: $headings-color;
        &:hover {
          color: lighten($headings-color, 25%);
        }
        > small {
          display: block;
          @include font-size($fs-xs);
          letter-spacing: 0;
          margin-top: $spacer / 4;
        }
      }
    }
  }
}
