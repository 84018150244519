
//
// Scaffolding
// --------------------------------------------------

* {
  box-sizing: border-box
}

html {
  font-size: 62.5%;
  tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  @include font-size($fs-base);
  color: $text-color;
  background-color: $body-bg;
  font-family: $font-family-base;
  font-weight: $font-weight-base;
  line-height: $line-height;
  font-feature-settings: 'palt' 1;
  letter-spacing: .05em;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  ::-moz-selection { background: $brand-primary; }
  ::selection { background: $brand-primary; }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}


// Reset fonts for relevant elements
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

// Links
a {
  color: $link-color;
  text-decoration: none;
  img {
    vertical-align: bottom;
    transition: opacity .1s linear;
  }
  &:hover,
  &:focus {
    outline: none;
    color: $link-hover-color;
    img, i { opacity: .8; }
  }
}

// Headings
h1, .h1, h2, .h2, h3, .h3,
h4, .h4, h5, .h5, h6, .h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
  > a {
    color: $headings-color;
    &:hover {
      color: lighten($headings-color, 25%);
    }
  }
}

h1, .h1 { @include font-size($fs-huge); }
h2, .h2 { @include font-size($fs-xl); }
h3, .h3 { @include font-size($fs-lg); }
h4, .h4 { @include font-size($fs-base); }
h5, .h5 { @include font-size($fs-sm); }
h6, .h6 { @include font-size($fs-xs); }

// Images
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

figure {
  margin-left: 0;
  margin-right: 0;
  figcaption {
    display: block;
    margin-top: 1em;
    font-size: 80%;
  }
}

table {
  margin: 1em -.5rem;
  th {
    font-weight: normal;
    white-space: nowrap;
  }
  th, td {
    padding: 0 .5rem;
    text-align: left;
    vertical-align: top;
  }
}

hr {
  border: none;
  margin: $spacer 0;
  @media #{$breakpoint-sm-up} {
    margin: ($spacer * 2) 0;
  }
}

ul, ol {
  padding-left: 1.5em;
}
