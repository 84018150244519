//
// Post
// --------------------------------------------------

p.en {
  @include font-size($fs-sm);
  line-height: 2.2;
}

.post {
  margin-bottom: $spacer * 2;
  @media #{$breakpoint-lg-up} {
    margin-bottom: $spacer * 3;
  }
}

.entry-header {
  margin-top: 1em;
  margin-bottom: $spacer / 2;
  @media #{$breakpoint-lg-up} {
    margin-bottom: $spacer;
  }
  a {
    color: $text-color;
    &:hover {
      color: lighten($text-color, 25%);
    }
  }
  > .entry-meta {
    display: flex;
    @include font-size($fs-xs);
  }
  > .entry-title {
    margin-top: 0.5rem;
  }
}

.entry-summary {
  @include clearfix;
}

.entry-title {
  small {
    display: block;
    @include font-size($fs-sm);
    font-weight: normal;
    letter-spacing: 0;
    margin-top: $spacer / 2;
  }
}

.entry-items {
  margin-top: $spacer;
  @media #{$breakpoint-lg-up} {
    margin-top: $spacer * 2;
  }
}

.entry-item {
  margin-top: $spacer;
  > .item-oembed,
  > .item-video {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    background: #000;
    > iframe,
    > object,
    > embed,
    > video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  > .item-caption {
    margin-top: -#{$spacer / 2};
    > small {
      @include font-size($fs-sm);
      color: $text-color-tender;
      display: block;
    }
  }
}

.entry-credit {
  &-title {
    margin-bottom: $spacer;
    position: relative;
    &:after {
      position: absolute;
      bottom: -#{$spacer / 2};
      left: 0;
      content: "";
      width: 1rem;
      height: 1px;
      border-bottom: 1px solid $text-color;
    }
  }
  &-content {
    @include font-size($fs-xs);
  }
}

.entry-footer {
  position: relative;
  margin-top: $spacer;
  @media #{$breakpoint-lg-up} {
    margin-top: $spacer * 2;
  }
}
