
//
// Extentions
// --------------------------------------------------

%link-inverted {
  color: $link-inverted-color;
  &:hover {
    color: $link-inverted-hover-color;
  }
}

%link-bordered {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

%list-unstyled {
  list-style: none;
  padding-left: 0;
}

%list-inline {
  margin: 0;
  > li {
    display: inline-block;
    margin: 0 1rem 0 0;
    &:last-child {
      margin: 0;
    }
  }
}

%dont-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

%text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%text-replace {
  text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

%reset-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  border-radius: 0;
  appearance: none;
}

.dl-horizontal {
  @include clearfix;
  margin: 0;
  > dt, > dd {
    float: left;
  }
  > dt {
    clear: left;
  }
  > dd {
    margin: 0 0 0 1rem;
  }
}

.text-center {
  text-align: center;
}

%middle-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
