//
// Infinite Ajax Sroll
// --------------------------------------------------

.posts-navigation {
  display: none;
}

@keyframes scale {
  0% {
    transform:scale(1);
  }
  50% {
    transform:scale(.5);
  }
  100% {
    transform:scale(1);
  }
}

.ias-trigger, .ias-spinner {
  margin-top: 4rem;
  display: block;
  min-height: 56px;
  width: 100%;
}

.ias-trigger {
  > a {
    @extend %button-base;
  }
}

.ias-spinner {
  position: relative;
  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px 0 0 -10px;
    width: 20px;
    height: 20px;
    animation: scale 1s ease-in-out infinite;
  }
}
