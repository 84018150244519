
//
// List
// --------------------------------------------------

.fb_iframe_widget > span {
	vertical-align: baseline !important;
}

.list-sub-category {
  @extend %list-unstyled;
  @extend %list-inline;
  margin-left: 1em;
  padding-left: 1em;
  border-left: 1px solid $border-color;
  color: $text-color;
}

.list-categories {
  @include font-size($fs-sm);
  @extend %list-unstyled;
  > li {
		display: inline-block;
		margin-right: 1rem;
		margin-bottom: $spacer / 4;
		@media #{$breakpoint-lg-up} {
			display: block;
			margin-right: 0;
		}
    &.current-cat {
      > a {
        color: $text-color;
        &:hover {
          color: lighten($text-color, 25%);
        }
      }
    }
    > a {
      color: lighten($text-color, 25%);
      &:hover {
        color: $text-color;
      }
    }
  }
}

.list-taxonomy {
	@extend %list-unstyled;
  @extend %list-inline;
	@include font-size($fs-xs);
}

.list-pagination {
	margin-top: $spacer * 2;
  @media #{$breakpoint-lg-up} {
    margin-top: 0;
  }
	@extend %list-unstyled;
	.type-works & {
		@media #{$breakpoint-lg-up} {
			position: absolute;
			right: $gutter / 2;
			bottom: 0;
		}
	}
	> li {
		margin-bottom: $spacer / 2;
		> a {
			display: block;
			@extend %text-replace;
			width: 80px;
			height: 40px;
			transition: opacity .1s linear;
			&:hover {
				opacity: .5;
			}
		}
	}
	.prev {
		> a {
			background-image: url(#{$images}button-previous.svg);
		}
	}
	.next {
		> a {
			background-image: url(#{$images}button-next.svg);
		}
	}
}
