
//
// Navigation
// --------------------------------------------------

header.banner {
  text-align: center;
  @media #{$breakpoint-lg-up} {
    text-align: left;
  }
  position: relative;
  padding-top: $spacer;
  padding-bottom: $spacer;
  transition: max-height .1s linear;
  .is-open & {
    z-index: 10;
    position: fixed;
    top: 0;
    left :0;
    width: 100%;
    height: 100%;
    background: #fff;
    .nav-primary {
      display: block;
    }
  }
}

.brand {
  display: none;
  &-horizontal {
    display: inline-block;
  }
  @media #{$breakpoint-lg-up} {
    &-horizontal {
      display: none;
    }
    &-rectangle {
      display: inline-block;
    }
  }
}

footer.content-info {
  @include font-size($fs-xs);
  padding-top: $spacer;
  padding-bottom: $spacer;
  text-align: center;
  @media #{$breakpoint-lg-up} {
    text-align: left;
  }
}

.nav {
  display: flex;
  @extend %list-unstyled;
  .is-open & {
    display: block;
    @include font-size($fs-xl);
  }
  li {
    a {
      color: lighten($text-color, 25%);
      &:hover {
        color: $text-color;
      }
    }
    &.active, &.current-category-ancestor {
      > a {
        color: $text-color;
      }
    }
  }
}

.nav-primary {
  display: none;
  > ul {
    > li {
      margin-right: 0;
      margin-bottom: $spacer / 2;
    }
  }
  @media #{$breakpoint-lg-up} {
    display: block;
    > ul {
      > li {
        margin-right: 2rem;
        margin-bottom: 0;
      }
    }
  }
  .is-open & {
    @extend %middle-center;
  }
}

.sub-menu {
  display: none;
  @extend %list-unstyled;
  @include font-size($fs-xs);
}

.menu-item-has-children {
  position: relative;
  &.active {
    .sub-menu {
      display: flex;
    }
  }
  .is-open & {
    .sub-menu {
      display: block;
      position: static;
      @include font-size($fs-base);
      > li {
        margin-right: 0;
        margin-top: $spacer / 2;
      }
    }
  }
  .sub-menu {
    position: absolute;
    bottom: -2.6rem;
    left: 0;
    > li {
      white-space: nowrap;
      margin-right: 2rem;
      margin-top: 0;
    }
  }
}

.menu-works {
  .menu-item {
    &:before {
      margin-right: .7em;
      content: '';
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    &.menu-about-architecture:before {
      content: "\e900";
      color: #00b9ef;
    }
    &.menu-books-texts:before {
      content: "\e901";
      color: #856daf;
    }
    &.menu-in-the-museums:before {
      content: "\e902";
      color: #e94820;
    }
    &.menu-objects:before {
      content: "\e903";
      color: #fabe00;
    }
    &.menu-own-initiated-projects:before {
      content: "\e904";
      color: #45b035;
    }
  }
}

.nav-menu {
	position: fixed;
  top: 4rem;
  right: $gutter / 2;
  width: $bar-width;
	height: $bar-height + $bar-spacing * 2;
	cursor: pointer;
  z-index: 11;
  @media #{$breakpoint-sm-up} {
    right: $gutter;
  }
  @media #{$breakpoint-lg-up} {
    display: none;
  }
}

.bar,
.bar:after,
.bar:before {
  width: $bar-width;
	height: $bar-height;
}

.bar {
	position: relative;
	transform: translateY($bar-spacing);
	background: rgba(0,0,0, 1);
	transition: all 0s .3s;
  &.animate {
    background: rgba(0,0,0, 0);
    &:before {
    	bottom: 0;
    	transform: rotate(-45deg);
    	transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
    }
    &:after {
    	top: 0;
    	transform: rotate(45deg);
    	transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
    }
  }
  &:before {
  	content: "";
  	position: absolute;
  	left: 0;
  	bottom: $bar-spacing;
  	background: rgba(0,0,0, 1);
  	transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  &:after {
  	content: "";
  	position: absolute;
  	left: 0;
  	top: $bar-spacing;
  	background: rgba(0,0,0, 1);
  	transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
}
