/* =TinyMCE Settings
-------------------------------------------------------------- */

.mce-content-body {
  margin-left: $gutter / 2;
  margin-right: $gutter / 2;
  table {
    th {
      font-weight: normal;
      white-space: nowrap;
    }
    th, td {
      padding: 0 .5rem;
      text-align: left;
      vertical-align: top;
    }
  }
}
