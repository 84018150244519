
//
// Headings
// --------------------------------------------------

.page-header {
  +.sub-header {
    margin: $spacer 0;
  }
  + .ias-container {
    margin-top: $spacer;
  }
}
